import React, { useEffect, useState } from 'react';
import '../styles/ReferralModal.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUI } from '../utils/UIContext';

const NewsletterModal = ({ onClose, player }) => {
  const { handleNewAlert, handleAlertType } = useUI();


  const subscribeSchema =
    yup.object().shape({
      email: yup.string().email().required('Please enter a valid email')
    });


  const formik = useFormik({
    initialValues: {
      email: player.email,
    },
    validationSchema: subscribeSchema,
    onSubmit: async (values, { resetForm }) => {
        // API call to MailChimp
          try {
            const mailChimpResponse = await fetch("/api/newsletter-signup", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify({ "email": values.email })
            });

            const returnMessage = await mailChimpResponse.json()

        if (!mailChimpResponse.ok) {
          if (returnMessage.error === "Member Exists") {
            // Tell player they have already subscribed
            handleNewAlert("You’re already a subscriber! Stay tuned for updates!");
            handleAlertType('info');
          } else {
            throw new Error('Failed to add subscriber to MailChimp');
          }
        } else {
          resetForm();
          onClose();
          handleNewAlert("Thanks for joining the newsletter! You earned 1 point!");
          handleAlertType('success');
        }
      } catch (error) {
        console.error('MailChimp error:', error);
        throw new Error(error.message);
      }
    }
  });


  return (
    <div className='news-modal-overlay'>
      <div className='news-modal-content'>
        <div className='news-modal-header'>
          <button onClick={onClose} id='modal-exit'>X</button>
          <h1>Join Monthly Newsletter</h1>
        </div>
        <div className='news-modal-body'>
          <p>Join our monthly newsletter to stay updated on the latest happenings at AMA Earth Group. By subscribing, you'll also earn additional points, increasing your chances of winning exciting prizes!</p>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='email'
              required
            />
            {formik.errors.email && formik.touched.email ? <div>{formik.errors.email}</div> : null}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewsletterModal
