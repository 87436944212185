import React from 'react';
import './styles/MangroveApp.css';

function MangroveApp() {
    return (
        <div>
            <iframe src="https://mangrove-app.onrender.com" width = "100%" height="675"></iframe>
        </div>
    );
};
export default MangroveApp;
