import { createContext, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useUI } from "../utils/UIContext";


const GameContext = createContext(null);

export const GameData = () => useContext(GameContext);


export const GameProvider = ({ children }) => {
  const navigate = useNavigate()
  const [player, setPlayer] = useState(null)

  const { handleNewAlert, handleAlertType } = useUI()

  //~~~~~~~~~~~~~~~~~~~~PLAYER LOGIN

  const onPlay = (values, signUp) => {
    fetch(signUp ? "/api/player-register" : "/api/player-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
    .then((resp) => {
      if (resp.ok) {
        resp.json().then((player) => {
          setPlayer(player)
          navigate(`/lobby`, {replace:true})
          handleNewAlert("Complete tasks to increase your odds!");
          handleAlertType("success");
        })
      } else {
        resp.json().then((errorObj) => {
          handleNewAlert("Invalid Login Credentials. Please Check Your Email");
          handleAlertType("error");
        });
      }
    })
    .catch((err) => console.log(err))
  }

  //~~~~~~~~~~~~~~~~~~~~CHECK REFRESH
  //!Should be triggered by a useEffect where it is located
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const checkRefresh = () => {
    if (!player) {
      fetch("/api/current-player")
        .then((resp) => {
          if (resp.ok) {
            resp
              .json()
              .then((updatedPlayer => {
                setPlayer(updatedPlayer)
              }))
          } else if (resp.status === 401) {
            fetch("/api/refresh-player", {
              method: "POST",
              headers: {
                'X-CSRF-TOKEN': getCookie('csrf_refresh_token'),
              }
            })
              .then(resp => {
                if (resp.ok) {
                  resp
                    .json()
                    .then((updatedPlayer => {
                      setPlayer(updatedPlayer)
                    }))
                } else {
                  resp
                    .json()
                    .then(console.log('Not Authorized'))
                }
              })
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    } else {
      // navigate(`/profile`); //may need to change this at some point
    }
  }
  useEffect(() => {
    checkRefresh()
  }, [player])

  //~~~~~~~~~~~~~~~~~~~~LOGOUT
  // const onLogout = () => {
  //   fetch("/logout", { method: "DELETE" })
  //     .then(() => {
  //       setUser(null)
  //       navigate("/")
  //       handleNewAlert('Successfully logged out')
  //       handleAlertType('success')
  //     })
  //     .catch(err => console.log(err));
  // };


  return (
    <GameContext.Provider value={{ player, onPlay }}>
      {children}
    </GameContext.Provider>
  )
}

export const useGame = () => {
  return useContext(GameContext)
}
