import React, { useEffect, useState } from 'react';
import '../styles/ReferralModal.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUI } from '../utils/UIContext';

const VolunteerModalLobby = ({ onClose}) => {
  const { handleNewAlert, handleAlertType } = useUI();

  //!IN THE FUTURE, QUERY EVENTS TABLE AND DISPLAY ALL AVAILABLE EVENTS IN LIST FORMAT (MUI-ListItem)

  return (
    <div className='news-modal-overlay'>
      <div className='news-modal-content'>
        <div className='news-modal-header'>
          <button onClick={onClose} id='modal-exit'>X</button>
          <h1>Volunteer for an Event!</h1>
        </div>
        <div className='news-modal-body'>
          <p>We are currently planning the next Ama Earth Group nature healing event. Please stay tuned!
            In the meantime, anybody that has volunteered for an Ama event in the past will receive automatic credit for their contributions.</p>
        </div>
      </div>
    </div>
  )
}

export default VolunteerModalLobby
