import React, {useEffect, useState, useRef, useCallback} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import "../styles/EventCalendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import VolunteerModalComponent from '../components/VolunteerModalComponent';
import { useAuth } from '../auth/authProvider';



const localizer = momentLocalizer(moment)

const EventCalendar = () => {
  const [loading, setLoading] = useState(true)
  const [convertedEvents, setConvertedEvents] = useState([])
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState()
  const clickRef = useRef(null)
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const auth = useAuth()
  console.log(events)
  console.log(loading)

  //fetch all events
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true)
      try {
        const response = await fetch('/events')
        if (!response.ok) {
            throw new Error(`HTTP error! status ${response.status}`)
        }
        const events = await response.json()
        setEvents(events)
        const convertEvents = events.map(event => ({
          title: event.title,
          start: new Date(event.date_starttime),
          end: new Date(event.date_endtime),
          allDay: false,
        }));
        setConvertedEvents(convertEvents)
        setLoading(false)
      }
      catch (err) {
        console.error('Error fetching events:', err);
        alert('Failed to load events. Please try again later.')
      }
      setLoading(false)
    }

    fetchEvents()
  }, [])

  //close event modal
  const handleCloseModal = () => {
    setIsVolunteerModalOpen(false);
    setSelectedEvent(null);
  };

  //if there is a user
  const onVolunteer = async (selectedEvent) => {

    const values = {
      user_id: auth.user.id,
      event_id: selectedEvent.id
    }

    try {
      const response = await fetch(`/eventattendees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(values)
      })
      if (!response.ok) {
        throw new Error('Failed to add new event attendee')
      }

      const newEventAttendee = await response.json()

    }
    catch (error) {
      console.error('Error fetching events:', error);
      alert('Failed to load events. Please try again later.')
    }
  };

  //event is clicked
  const handleDoubleClickEvent = (clickedEvent) => {
    const fullEvent = events.find((event) => event.title === clickedEvent.title)
    setIsVolunteerModalOpen(true);
    console.log(fullEvent)
    setSelectedEvent(fullEvent);
  };

  if (loading) return <div className="loading-message">Loading calendar...</div>;
  return (
    <div className='calendar-container'>
          <Calendar
            localizer={localizer}
            views={['month', 'week', 'day']}
            events={convertedEvents}
            onDoubleClickEvent={handleDoubleClickEvent}
          />
          {isVolunteerModalOpen && selectedEvent && (
            <VolunteerModalComponent
              onClose={handleCloseModal}
              selectedVolunteerEvents={selectedEvent}
              onVolunteer={onVolunteer}
            />
          )}

    </div>
  )
}

export default EventCalendar
