/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import defaultAvatar from "../images/default.jpeg";
import { useAuth } from "../auth/authProvider";
import "../styles/Profile.css";
import { useUI } from "../utils/UIContext";
import { Button } from "@mui/material";

function Profile() {
  const auth = useAuth();
  const [userData, setUserData] = useState(null);
  const [totalDonated, setTotalDonated] = useState(
    auth.user?.total_donations || 0
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const [volunteeredEventsCount, setVolunteeredEventsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(auth.user?.username || "");
  const [avatar, setAvatar] = useState(auth.user?.avatar_url || "");
  const [avatarUrl, setAvatarUrl] = useState(defaultAvatar);
  const [projects, setProjects] = useState(null);
  const { handleNewAlert, handleAlertType } = useUI();
  const [open, setOpen] = useState(false);
  const [selectedWinner, setSelectedWinner] = useState("") //only for ADMIN

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/users/${auth.user.id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const userData = await response.json();
        setLoading(false); // Move this line before setUserData
        setUserData(userData);
        setAvatarUrl(userData.avatar_url || defaultAvatar);
        setSelectedProject(userData.selected_project_id);
        setUsername(userData.username || "");
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    if (auth.user) {
      fetchUserData();
    }
  }, [auth.user]);

  // Fetch all projects
  useEffect(() => {
    fetch("/api/projects")
      .then((r) => r.json())
      .then(setProjects)
      .catch((err) => console.log(err));
  }, []);

  const uploadAvatarAndUpdateProfile = async (
    avatarFile,
    userId,
    newUsername
  ) => {
    if (!avatarFile) return null;

    const formData = new FormData();
    formData.append("file", avatarFile);
    formData.append("user_id", userId);
    formData.append("username", newUsername);

    try {
      const response = await fetch("/api/s3-upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        handleNewAlert("Failed to upload avatar image");
        handleAlertType("error");
      }

      const data = await response.json();
      console.log(data);
      setAvatarUrl(data.avatar_url);
      console.log(data.avatar_url);

      return data;
    } catch (error) {
      handleNewAlert("Failed to upload avatar image");
      handleAlertType("error");
    }
  };

  // Define the updateUserProfile function
  const updateUserProfile = async (userId, userData) => {
    try {
      const response = await fetch(`/users/${userId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // handleNewAlert("Failed to update user profile")
        // handleAlertType('error')
        throw new Error(errorData.message || "Failed to update user profile");
      }

      const updatedUserData = await response.json();
      setUserData(updatedUserData);
      handleNewAlert("Updated user profile!");
      handleAlertType("success");
    } catch (error) {
      handleNewAlert("Failed to update user profile");
      handleAlertType("error");
      console.error("Error updating user profile:", error);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    if (!auth.user) return;
    try {
      let avatarUploadResult = null;
      if (avatar && avatar !== auth.user.avatar_url) {
        avatarUploadResult = await uploadAvatarAndUpdateProfile(
          avatar,
          auth.user.id,
          username
        );
      }
      if (avatarUploadResult !== null || avatar) {
        console.log(avatarUploadResult);
        const userDataToUpdate = {
          username,
          avatar_url: avatarUploadResult
            ? avatarUploadResult.avatar_url
            : avatar,
          selected_project_id: selectedProject,
        };
        await updateUserProfile(auth.user.id, userDataToUpdate);
      } else {
        console.log("no avatar selected");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectSelection = async (e) => {
    const projectId = e.target.value;
    setSelectedProject(parseInt(projectId));
  };

  //!Admin only function
  const selectWinner = async () => {
    try {
      const response = await fetch("/api/select-winner");
      if (!response.ok) {
        throw new Error("Unable to Select Winner")
      }
      const winnerEmailObj = await response.json()
      setSelectedWinner(winnerEmailObj.winner)
    }
    catch (error){
      console.error("Error fetching players:", error)
    }
  }

  if (loading) {
    return <div>Loading your profile...</div>;
  }

  if (!userData) {
    return <div>Welcome to Ama Earth Group. Here is your profile!</div>;
  }

  return (
    <div className="profile-page">
      <div className="profile-left">
        <div className="profile-card">
          <div className="profile-header">
            <div className="avatar-container">
              <img src={avatarUrl} alt="Avatar" className="profile-avatar" />
            </div>
            <div className="profile-details">
              <h1 className="profile-username">{username || "Your Name"}</h1>
              <p className="profile-bio">
                Passionate about making a difference for our planet.
              </p>
            </div>
          </div>
          {open ? (
            <form className="profile-form" onSubmit={handleProfileUpdate}>
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
              />

              <label htmlFor="avatar">Avatar</label>
              <input
                id="avatar"
                type="file"
                onChange={(e) => setAvatar(e.target.files[0])}
              />

              <label htmlFor="projectSelection">
                Select a Project to Support
              </label>
              <select
                id="projectSelection"
                value={selectedProject === null ? "" : selectedProject}
                onChange={handleProjectSelection}
              >
                {projects ? (
                  <>
                    <option disabled value="">
                      Select a Project to Support
                    </option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.title}
                      </option>
                    ))}
                  </>
                ) : (
                  <option disabled>Loading projects...</option>
                )}
              </select>
              <button type="submit">Save Changes</button>
            </form>
          ) : null}

          {open ? (
            <button onClick={() => setOpen(false)}>Cancel</button>
          ) : (
            <button onClick={() => setOpen(true)}>Edit Profile</button>
          )}
        </div>
      </div>

      <div className="profile-right">
        <div className="profile-section">
          <h2>Total Funds Contributed</h2>
          <p className="section-content">${userData.total_donations}</p>
        </div>

        <div className="profile-section">
          <h2>Events Volunteered For</h2>
          <p className="section-content">{userData.events_attended} events</p>
        </div>
        <div className="profile-section">
          <h2>Impact Focus</h2>
          <p className="section-content">
            {userData.project_selected
              ? userData.project_selected.title
              : "None"}
          </p>
        </div>
        {auth.user.is_admin &&
        <div className="profile-section">
          <div className="winner-selection">
          <h2>Sweepstakes Winner Selection</h2>
          {!selectedWinner &&
          <Button onClick={selectWinner} sx={{color: "white", background:'black'}} >Select Winner</Button>
          }
          <h3>{selectedWinner}</h3>
          </div>


        </div>}

        <div className="profile-section"></div>
      </div>
    </div>
  );
}

export default Profile;
