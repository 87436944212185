import React, {useState} from 'react';
import '../styles/AdminModals.css';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUI } from '../utils/UIContext';
import { useAuth } from '../auth/authProvider';

//!ADD IN SAFEGUARDS/VALIDATIONS/WARNINGS

const AddProjectModal = ({onClose}) => {
  const [projectImage, setProjectImage] = useState('');
  const [leadingOrganizationInput, setLeadingOrganizationInput] = useState('');
  const [leadingOrganizations, setLeadingOrganizations] = useState([]);
  const [urlInput, setUrlInput] = useState('');
  const { handleNewAlert, handleAlertType } = useUI();
  const auth = useAuth()

  const [contOrganizationInput, setContOrganizationInput] = useState('')
  const [contOrganizations, setContOrganizations] = useState([])
  const [contUrlInput, setContUrlInput] = useState('')

  const [newProjectData, setNewProjectData] = useState({
    title: "",
    img: 'placeholder',
    problem: "",
    solution: "",
    dedicated_funds: '',
    leading_org:'placeholder',
    leading_org_url: 'placeholder',
    contributing_org_name: 'placeholder',
    contributing_org_url: 'placeholder',
    share:"",
    progress_title:"",
    progress_url:"",
  })
  console.log(projectImage)

  //YUP SCHEMA
  const projectSchema = yup.object().shape({
    title: yup.string().required('Please enter a project title'),
    img: yup.string().required('Please select an image'),
    problem: yup.string().required('Please enter a project problem'),
    solution: yup.string().required('Please enter a project solution'),
    dedicated_funds: yup.number().integer().required('Please enter a whole number'),
    leading_org: yup.string().required('Please enter a leading organization'),
    leading_org_url: yup.string().required('Please enter a valid url for the leading organization'),
    contributing_org_name: yup.string().required('Please enter a supporting organization'),
    contributing_org_url: yup.string().required('Please enter a valid url for the contributing organization'),
    share: yup.string().url().required('Please enter a valid url'),
    progress_title: yup.string().required('Please enter a valid progress title'),
    progress_url: yup.string().url().required('Please enter a valid url for the progress')
  })

  //~Image Upload
  const uploadProjectImage = async (projectFile, userId, title) => {
    if (!projectFile) return null;

    const formData = new FormData();
    formData.append('file', projectFile);
    formData.append('user_id', userId);
    formData.append('title', title);

    try {
      const response = await fetch('/api/s3-project-upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        handleNewAlert("Failed to upload project image")
        handleAlertType('error')
        throw new Error('Failed to upload project image');
      }

      const data = await response.json();
      setNewProjectData(prevState => ({ ...prevState, img: data.image_url }))

      return data.image_url;
    } catch (error) {
      handleNewAlert("Failed to upload project image")
      handleAlertType('error')
      throw error;
    }
  };

  //~POST Request with Formik
  const formik = useFormik({
    initialValues: newProjectData,
    validationSchema: projectSchema,
    onSubmit: async (values, {resetForm}) => {
      console.log('values')
      try {
        let imageUrl = null
        if (projectImage) {
          imageUrl = await uploadProjectImage(projectImage, auth.user.id, values.title )
          console.log(imageUrl)
        }

        const formData = {
          ...values, img: imageUrl, leading_org: newProjectData.leading_org,
          leading_org_url: newProjectData.leading_org_url,
          contributing_org_name: newProjectData.contributing_org_name,
          contributing_org_url: newProjectData.contributing_org_url}
          //Create new project in database
          const response = await fetch('/api/projects', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
          })

          if (!response.ok) {
            handleNewAlert('Failed to create new project');
            handleAlertType('error');
            throw new Error('Failed to create new project');
          }
          resetForm();
          setLeadingOrganizations('')
          setContOrganizations('')
          setProjectImage('')
          handleNewAlert('Project Added!');
          handleAlertType('success');
          onClose()
        }
        catch (error) {
          handleNewAlert(error.message || 'An error occurred');
          handleAlertType('error');
        }
      }
    })
    // console.log(projectImage)
    console.log(formik.values)

  const handleAddLeadingOrganization = () => {
    if (leadingOrganizationInput.trim() !== '' && urlInput.trim() !== '') {
      const newOrganization = { leadingOrganization: leadingOrganizationInput.trim(), url: urlInput.trim() };
      setLeadingOrganizations([...leadingOrganizations, newOrganization]);
      setNewProjectData((oldData) => ({ ...oldData, leading_org_url: [...leadingOrganizations, newOrganization].map(org => org.url).join(','),
        leading_org: [...leadingOrganizations, newOrganization].map(org => org.leadingOrganization).join(',') }))
      //Clear input fields
        setLeadingOrganizationInput('');
      setUrlInput('');
    }
  }
  //remove organizations added
  const handleRemoveLeadingOrganization = (index) => {
    const updatedOrganizations = [...leadingOrganizations];
    updatedOrganizations.splice(index, 1);

    setLeadingOrganizations(updatedOrganizations);

    setNewProjectData((oldData) => ({
      ...oldData,
      leading_org_url: updatedOrganizations.map(org => org.url).join(','),
      leading_org: updatedOrganizations.map(org => org.leadingOrganization).join(',')
    }));
  };

  const handleAddContOrganization = () => {
    if (contOrganizationInput.trim() !== '' && contUrlInput.trim() !== '') {
      const newOrganization = { contOrganization: contOrganizationInput.trim(), url: contUrlInput.trim() };

      setContOrganizations([...contOrganizations, newOrganization]);
      setNewProjectData((oldData) => ({
        ...oldData,
        contributing_org_name: [...contOrganizations, newOrganization].map(org => org.contOrganization).join(','),
        contributing_org_url: [...contOrganizations, newOrganization].map(org => org.url).join(',')
      }));
      // Clear input fields
      setContOrganizationInput('');
      setContUrlInput('');
    }
  }

  const handleRemoveContOrganization = (index) => {
    const updatedOrganizations = [...contOrganizations];
    updatedOrganizations.splice(index, 1);
    setContOrganizations(updatedOrganizations);
    setNewProjectData((oldData) => ({
      ...oldData,
      contributing_org_name: updatedOrganizations.map(org => org.contOrganization).join(','),
      contributing_org_url: updatedOrganizations.map(org => org.url).join(',')
    }));
  };

  return (
    <div className='admin-modal-overlay'>
      <div className='admin-modal-content'>
        <div className='admin-form-container'>
          <form className='project-form' onSubmit={formik.handleSubmit}>
            <h3><label>Add New Project</label></h3>
            <div className='admin-input-container'>
              <input
                value={formik.values.title}
                placeholder='Project Title'
                required
                name='title'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.title && formik.touched.title ? < >{formik.errors.title}</> : null}
              <textarea
                value={formik.values.problem}
                placeholder='Problem'
                required
                name='problem'
                rows='5'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.problem && formik.touched.problem ? <>{formik.errors.problem}</> : null}
              <textarea
                value={formik.values.solution}
                placeholder='Solution'
                required
                name='solution'
                rows='5'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
                {formik.errors.solution && formik.touched.solution ? < >{formik.errors.solution}</> : null}
              <input
                value={formik.values.dedicated_funds}
                placeholder='$ - Dedicated Funds'
                required
                name='dedicated_funds'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
                {formik.errors.dedicated_funds && formik.touched.dedicated_funds ? (
                  <>{formik.errors.dedicated_funds}</>
                ) : null}
              <input
                value={formik.values.share}
                placeholder='URL - Share Link'
                required
                name='share'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.share && formik.touched.share ? < >{formik.errors.share}</> : null}
              <input
                id='project-image'
                type='file'
                required
                onChange={(e) => setProjectImage(e.target.files[0])}
                name={projectImage ? projectImage.name : null}
                // onBlur={formik.handleBlur}
                />
              {formik.errors.img && !projectImage ? <>{formik.errors.img}</> : null}
              {/* <Divider sx={{ marginBottom: '10px', backgroundColor: 'black' }} /> */}
              <div className='org-container'>
                <input
                  value={leadingOrganizationInput}
                  type='text'
                  name='leading_org'
                  // required
                  placeholder='Leading Organization Name'
                  onChange={(e) => setLeadingOrganizationInput(e.target.value)}
                  onBlur={formik.handleBlur}
                />
                <input
                  value={urlInput}
                  type='url'
                  // required
                  name='leading_org_url'
                  placeholder='Leading Organization URL'
                  onChange={(e) => setUrlInput(e.target.value)}
                  onBlur={formik.handleBlur}
                />
                <button onClick={handleAddLeadingOrganization}>Add</button>
              </div>
              <div className='chips-container'>
                {leadingOrganizations ? leadingOrganizations.map((org, index) => (
                  <Chip
                  sx={{marginRight: '1vh', marginLeft:'1vh'}}
                  color='primary'
                  size='medium'
                  key={index}
                  label={org.leadingOrganization}
                  onDelete={() => handleRemoveLeadingOrganization(index)} />
                )): null}
              </div>
              <div className='org-container'>
                <input
                  value={contOrganizationInput}
                  type='text'
                  // required
                  name='contributing_org_name'
                  placeholder='Contributing Organization Name'
                  onChange={(e) => setContOrganizationInput(e.target.value)}
                />
                <input
                  value={contUrlInput}
                  type='url'
                  // required
                  placeholder='Contributing Organization URL'
                  onChange={(e) => setContUrlInput(e.target.value)}
                />
                <button onClick={handleAddContOrganization}>Add</button>
              </div>
              <div className='chips-container'>
                {contOrganizations ? contOrganizations.map((org, index) => (
                  <Chip
                  sx={{marginRight: '1vh', marginLeft:'1vh'}}
                  color='primary'
                  size='medium'
                  key={index}
                  label={org.contOrganization}
                  onDelete={() => handleRemoveContOrganization(index)} />
                )): null}
              </div>
              {/* <Divider sx={{marginBottom: '10px', backgroundColor: 'black'}}/> */}
                <textarea
                value={formik.values.progress_title}
                  className='progress-info'
                  placeholder='Progress Title & Information'
                  required
                  name='progress_title'
                  rows='5'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              {formik.errors.progress_title && formik.touched.progress_title ? < >{formik.errors.progress_title}</> : null}
                <input
                  value={formik.values.progress_url}
                  type='url'
                  required
                  placeholder='Progress URL'
                  name='progress_url'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              {formik.errors.progress_url && formik.touched.progress_url ? < >{formik.errors.progress_url}</> : null}
                {/* <button onClick={handleAddContOrganization}>Add</button> */}
            </div>
            <button onClick={onClose} className='modal-button'> Cancel</button>
            <button type='submit' className='modal-button'> Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddProjectModal
