import React from 'react';
import { useGame } from './gameProvider';
import { Navigate } from 'react-router-dom';

const RequirePlayer = ({ children }) => {
  const {player} = useGame()

  if (!player) {
    return <Navigate to='/' />
  }

  return children
}

export default RequirePlayer
