// LogoutButton.js

import React from 'react';
import { useAuth } from '../auth/authProvider';

function LogoutButton() {
  const auth = useAuth()

  const handleLogout = async () => {
    auth.onLogout()
  };

  // Use 'fixed-logout-button' for positioning and 'logout-button' for styling
  return (
    <div className="fixed-logout-button">
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default LogoutButton;
