import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import '../styles/Login.css';
import banner from '../images/ama-banner-full.png';
import googleLogo from '../images/google_logo.png';
import { useAuth } from '../auth/authProvider';
import { useUI } from '../utils/UIContext';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = useAuth()
  const { handleNewAlert, handleAlertType } = useUI();

  useEffect(() => {
    try {
        window.google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          { theme: 'filled_blue', size: 'large', width: '310px' }
        )
    } catch (error) {
      console.error("Error rendering Google Button:", error)
    }

  }, [])

  const handleLogin = async (e) => {
    e.preventDefault()

    const values = {email, password}
    auth.onAuthenticate(values, false)
  };

  const handleSignUpRedirect = () => {
    navigate('/signup'); // Redirect to the sign-up page
  };

  //function that fires backend to send email
  const sendPasswordResetEmail = async (email) => {
    try {
      const response = await fetch(`/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({email: email})
      })
      if (!response.ok) {
        handleNewAlert("Failed to send email, please try again.")
        handleAlertType('error')
        throw new Error("Failed to send email")
      }
      handleNewAlert("Password reset email sent. Please check your email to reset your password.")
      handleAlertType('info')
    }
    catch (error) {
      console.error('Error sending email:', error)
    }
  }
  //function to handle when user clicks reset password
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');
    if (!email) { // Check if the email address is not empty
      handleNewAlert("Please enter your email")
      handleAlertType("warning")
      // setError('Please enter your email address.');
        return; // Exit the function if the email is empty
    }
    try {
      await sendPasswordResetEmail(email);
    } catch (error) {
      console.error(error);
      setError('An unexpected error occurred. Please try again later.');
    }
};

  return (
    <div className="login-page">
      <div className="banner-container">
        <img src={banner} alt="AMA Banner" className="home-banner" />
        <div className="form-container">
          <form onSubmit={handleLogin} className="login-form">
            {error && <p className="error-message">{error}</p>}
            <div className="input-container">
              <input
                type="email"
                className="login-input"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                className="login-input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="button-container">
              <button type="submit" className="login-button">
                Login
              </button>
              <button type="button" onClick={handleSignUpRedirect} className="signup-button">
                Sign Up
              </button>
            </div>
            <div id="google-signin-button">
              {/* <img src={googleLogo} alt="Google sign-in" />
              <span>Sign in with Google</span> */}
            </div>
            <a href="#" className="forgot-password-link" onClick={handleForgotPassword}>
              Forgot your password?
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;
