import React, {useState, useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from '../images/ama-logo.png';
import "../styles/ReferralChallenge.css";
import ReferralSignInModal from '../components/ReferralSignInModal';

const ReferralChallenge = () => {
  const [openModule, setOpenModule] = useState(false)
  const [referringPlayerId, setReferringPlayerId] = useState()
  const location = useLocation()



  const handleCloseModal = () => {
    setOpenModule(false)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const playerId = searchParams.get('player_id');
    if (playerId) {
      setReferringPlayerId(playerId);
    } else {
      throw new Error('Failed to retrieve referring player id')
    }
  }, [location.search])


  return (
    <div className="referral-landing-page">
      <div className="referral-header">
        <h1>Welcome to AMA Earth Group!</h1>
      </div>
      <div className="referral-content">
        <p>
          Your friend has invited you to join AMA Earth Group, a community dedicated to making a positive impact on the environment. By joining us, you’ll be part of exciting initiatives, earn rewards, and contribute to a greener planet.
        </p>
        <p>
          As a special thank you for joining through a referral, you’ll start with bonus points that you can use towards your rewards. Just complete the sign-up process to get started!
        </p>
        <ul>
          <li><strong>Join the Community:</strong> Connect with like-minded individuals who are passionate about environmental sustainability.</li>
          <li><strong>Earn Rewards:</strong> Participate in activities and earn points that can be redeemed for rewards.</li>
          <li><strong>Make an Impact:</strong> Take part in initiatives that help protect our planet.</li>
        </ul>
        <button onClick={() => setOpenModule(true)} className="sign-up-button">Join Now</button>
      </div>
      {openModule && <ReferralSignInModal onClose={handleCloseModal} playerId={referringPlayerId}/>}
    </div>
  )
}

export default ReferralChallenge
