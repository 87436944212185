import React, { useEffect, useState } from "react";
import "../styles/ReferralModal.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUI } from "../utils/UIContext";

const ReferralModal = ({ onClose, playerId }) => {
  const { handleNewAlert, handleAlertType } = useUI();

  const referralSchema = yup.object().shape({
    email: yup.string().email().required("Please enter a valid email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      player_id: playerId,
    },
    validationSchema: referralSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch("/api/make-referral", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        });
        if (!response.ok) {
          handleNewAlert("Failed to send referral, please try again.");
          handleAlertType("error");
          throw new Error("Failed to send referral");
        }
        handleNewAlert("Referral sent!");
        handleAlertType("success");
      } catch (error) {
        throw new Error(error);
      }
      onClose();
    },
  });

  return (
    <div className="news-modal-overlay">
      <div className="news-modal-content">
        <div className="news-modal-header">
          <button onClick={onClose} id="modal-exit">
            X
          </button>
          <h1>Refer a Friend</h1>
        </div>
        <div className="news-modal-body">
          <p>
            Invite your friends to join us and earn rewards! Here's how it
            works:
          </p>
          <ul>
            <li>
              <strong>Refer a Friend:</strong> Enter the email of your friend in
              the form below.
            </li>
            <li>
              <strong>Earn Points:</strong> You will receive 2 points for each
              successful referral.
            </li>
          </ul>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              required
            />
            {formik.errors.email && formik.touched.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
