import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import DonateModalComponent from "../components/DonateModalComponent";
import VolunteerModalComponent from "../components/VolunteerModalComponent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.css";
import { useAuth } from "../auth/authProvider";

function Projects() {
  const auth = useAuth();
  const location = useLocation();
  const { selectedProjectIndex = 0 } = location.state || {};
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSwipeOverlay, setShowSwipeOverlay] = useState(true);

  // console.log(isMobile)

  //fetch projects from backend
  useEffect(() => {
    //function to fetch projects
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const response = await fetch("/api/projects");
        if (!response.ok) {
          throw new Error(`HTTP error! status ${response.status}`);
        }
        const data = await response.json();
        setProjects(data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchProjects();
    // Cleanup function
    // Function to handle screen size change
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      // Remove event listener when component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onVolunteer = async (selectedEvent) => {
    const values = {
      user_id: auth.user.id,
      event_id: selectedEvent.id,
    };

    try {
      const response = await fetch(`/eventattendees`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error("Failed to add new event attendee");
      }

      const newEventAttendee = await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  function Collapsible({ title, children, isOpenByDefault = false }) {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);

    return (
      <div>
        <div className="collapsible-header" onClick={() => setIsOpen(!isOpen)}>
          {title} {isOpen ? "▲" : "▼"}
        </div>
        {isOpen && <div className="collapsible-content">{children}</div>}
      </div>
    );
  }

  //! DYNAMIC ARROWS
  useEffect(() => {
    // Function to handle screen size change
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      // Remove event listener when component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !isMobile,
    swipeToSlide: isMobile,
    initialSlide: selectedProjectIndex, // Set the initial slide based on the selected project index
  };

  // Function to hide the swipe overlay after 5 seconds
  useEffect(() => {
    if (showSwipeOverlay) {
      const timer = setTimeout(() => {
        setShowSwipeOverlay(false);
      }, 3000);

      // Clear the timer if component unmounts
      return () => clearTimeout(timer);
    }
  }, [showSwipeOverlay]);

  const handleDonateClick = (project) => {
    setSelectedProject(project);
    setIsDonateModalOpen(true); // Open the donate modal
  };

  const [selectedVolunteerEvents, setSelectedVolunteerEvents] = useState([]);

  const handleVolunteerClick = (project) => {
    setSelectedProject(project);
    setSelectedVolunteerEvents(project.events); // Store the volunteer events for the selected project
    setIsVolunteerModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDonateModalOpen(false);
    setIsVolunteerModalOpen(false);
  };

  if (loading)
    return <div className="loading-message">Loading projects...</div>;
  return (
    <div className="project-page projects-background">
      <div className="projects-carousel">
        <Slider {...carouselSettings}>
          {projects.map((project, index) => (
            <div
              key={index - selectedProjectIndex}
              className="projects-container"
            >
              <div className="slide-header">
                <h2 className="hd">{project.title}</h2>{" "}
                {/* Dynamic header based on project title */}
              </div>
              <img
                src={project.img}
                alt={project.title}
                className="project-image"
              />
              <div className="project-content">
                <div className="project-actions">
                  <button
                    className="donate-button sub"
                    projectData={project}
                    onClick={() => handleDonateClick(project)}
                    style={{ color: "black" }}
                  >
                    Donate to {project.title}
                  </button>
                  <button
                    className="volunteer-button sub"
                    style={{ color: "black" }}
                    onClick={() => handleVolunteerClick(project)}
                  >
                    Volunteer 🛠️
                  </button>
                  {project.progress_url && (
                    <a
                      href={project.share}
                      className="share-button sub"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black" }}
                    >
                      Share 📣
                    </a>
                  )}
                </div>
                <div className="project-details sub">
                  <Collapsible
                    title="Problem & Solution:"
                    isOpenByDefault={true}
                  >
                    <p>
                      <div className="sub" style={{ color: "black" }}>
                        <p>
                          <strong>Problem:</strong> {project.problem}
                        </p>
                        <p>
                          <strong>Solution:</strong> {project.solution}
                        </p>
                      </div>
                    </p>
                  </Collapsible>
                  <Collapsible title="Leading Organizations:">
                    <ul className="sub">
                      {project.leading_org.split(",").map((org, orgIndex) => (
                        <li key={orgIndex}>
                          <a
                            className="org-links"
                            href={project.leading_org_url.split(",")[orgIndex]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {org}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Collapsible>
                  <Collapsible title="Supporting Businesses">
                    <ul className="sub">
                      {project.contributing_org_name
                        .split(",")
                        .map((org, orgIndex) => (
                          <li key={orgIndex}>
                            <a
                              className="org-links"
                              href={
                                project.contributing_org_url.split(",")[
                                  orgIndex
                                ]
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {org}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </Collapsible>
                  <Collapsible title="Total Funds Raised:" className="hd">
                    <p className="sub" style={{ color: "black" }}>
                      ${project.dedicated_funds}
                    </p>
                  </Collapsible>
                  <Collapsible
                    title="Progress"
                    style={{ fontFamily: "Raleway" }}
                  >
                    <ul className="sub">
                      {project.progress_url
                        .split(",")
                        .map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a
                              className="org-links"
                              href={link === "TBD" ? null : link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {project.progress_title}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </Collapsible>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {isMobile && showSwipeOverlay && (
          <div className="swipe-overlay">
            <p>Swipe right or left to see more projects</p>
          </div>
        )}
      </div>
      {isDonateModalOpen && (
        <DonateModalComponent
          onClose={handleCloseModal}
          projectData={selectedProject}
          currentUser={auth.user}
        />
      )}
      {isVolunteerModalOpen && (
        <VolunteerModalComponent
          onClose={handleCloseModal}
          projectData={selectedProject}
          selectedVolunteerEvents={selectedVolunteerEvents}
          onVolunteer={onVolunteer}
        />
      )}
    </div>
  );
}

export default Projects;
