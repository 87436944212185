import React from "react";
import '../styles/About.css'; // Keep the CSS import for other styles

function About() {
  return (
    <div className="about-page">
      {/* Hero Section */}
      <section 
        className="hero-section"
        style={{
          backgroundImage: `url('/images/ama_splash_circle.jpeg')`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <div className="hero-content">
          <h1 className="hero-title">Restoring Nature. Empowering Communities. Regenerating Ecosystems.</h1>
        </div>
      </section>

      {/* About Section */}
      <section className="section about-section">
        <div className="content-wrapper">
          <h2 className="section-title">What We Do</h2>
          <p className="section-text">
          Ama Earth Group integrates indigenous wisdom with advanced climate technologies—from AI to geospatial analytics—to heal the planet. Our approach leverages modern indigeneity, combining cutting-edge tools with ancestral knowledge to drive environmental regeneration at the local level, fostering thriving ecosystems and bringing balance back to nature.
          </p>
          <p className="section-text">
          Ama Earth Group is a purpose-driven, for-profit organization incorporated in Borikén (Puerto Rico).
          </p>

        </div>
      </section>

      {/* Divider Line */}
      <hr className="hr-line" />

      {/* Vision Section */}
      <section className="section mission-section">
        <div className="content-wrapper">
          <h2 className="section-title">Our Vision</h2>
          <p className="section-text">
          We see a planet where hyper-local climate actions ripple into global impact, bringing life back to our forests, rivers, oceans, and skies. Guided by the principles of modern indigeneity, we honor the Earth’s rhythms and cycles while driving innovation that regenerates our environment.
          </p>
        </div>
      </section>

      {/* Divider Line */}
      <hr className="hr-line" />

      {/* Team Section */}
      <section className="section vision-section">
        <div className="content-wrapper">
          <h2 className="section-title">Our Team</h2>
          <div className="team-member"></div>
          <img src="/ama-headshot.JPG" alt="Isiah Cruz" className="team-photo" />
          <h3 className="team-name">Isiah Cruz</h3>
          <p className="team-position">Founder & CEO</p>

      {/* LinkedIn Button below title */}
          <a href="https://www.linkedin.com/in/isiahcruz/" target="_blank" rel="noopener noreferrer">
          <img src="/linkedin-logo.png" alt="LinkedIn" className="linkedin-logo" />
          </a>
        </div>
      </section>
    </div>
  );
}

export default About;
