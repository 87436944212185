import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import logo from '../images/ama-logo.png';
import '../styles/ForgotPassword.css';
import { useUI } from '../utils/UIContext';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const { handleNewAlert, handleAlertType } = useUI();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        setPasswordsMatch(false);
        return;
      }

      const values = {
        password
      }

      try {
        const response = await fetch(`/users/${email}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        })
        if (!response.ok) {
          handleNewAlert('Failed to update password')
          handleAlertType('error')
          throw new Error('Failed to update password')
        }
        handleNewAlert('Password successfully reset!')
        handleAlertType('success')
        // alert('Password successfully reset!')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
      }
      catch (error) {
        console.log(error)
      }


    };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    // Reset passwordsMatch state when confirm password changes
    if (passwordsMatch === false && password === value) {
      setPasswordsMatch(true);
    }
  };

    return (
    <div className='password-container'>
      <img src={logo} alt="AMA Logo" />
      <h2>Reset Password</h2>
            <form className='password-form' onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Confirm your new password"
                value={confirmPassword}
                onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                required
              />
              {!passwordsMatch && <div style={{ color: 'red', marginBottom:'20px' }}>Passwords do not match!</div>}
                <button type="submit">Reset Password</button>
            </form>
      <Link className='success-link' to='/login'>Return to Login</Link>
    </div>
    );
};

export default ForgotPassword;
