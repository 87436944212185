import React, { useState, useEffect } from 'react';
import { useUI } from '../utils/UIContext';
import { useAuth } from '../auth/authProvider';
import '../styles/AdminModals.css';
import { useFormik } from 'formik';
import * as yup from 'yup';

const EditProjectModal = ({onClose}) => {

  const [date, setDate] = useState(null)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState([])
  const { handleNewAlert, handleAlertType } = useUI();


  //initial fetch of projects
  useEffect(() => {
    fetch('/api/projects')
      .then(r => r.json())
      .then(setProjects)
      .catch(err => console.log(err));
  }, []);

  //YUP SCHEMA
  const projectSchema = yup.object().shape({
    title: yup.string().required('Please enter a project title'),
    img: yup.string().required('Please select an image'),
    problem: yup.string().required('Please enter a project problem'),
    solution: yup.string().required('Please enter a project solution'),
    dedicated_funds: yup.number().integer().required('Please enter a whole number'),
    leading_org: yup.string().required('Please enter a leading organization'),
    leading_org_url: yup.string().required('Please enter a valid url for the leading organization'),
    contributing_org_name: yup.string().required('Please enter a supporting organization'),
    contributing_org_url: yup.string().required('Please enter a valid url for the contributing organization'),
    share: yup.string().url().required('Please enter a valid url'),
    progress_title: yup.string().required('Please enter a valid progress title'),
    progress_url: yup.string().url().required('Please enter a valid url for the progress')
  })


  const formik = useFormik({
    initialValues: {
      title: "",
      img: '',
      problem: "",
      solution: "",
      dedicated_funds: '',
      leading_org: '',
      leading_org_url: '',
      contributing_org_name: '',
      contributing_org_url: '',
      share: "",
      progress_title: "",
      progress_url: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values, { resetForm }) => {

      resetForm()
      setDate(null)
      handleNewAlert('Project Updated!');
      handleAlertType('success');
      onClose()
    }
  })

  return (
    <div className='admin-modal-overlay'>
      <div className='admin-modal-content'>
        <div className='admin-form-container'>
          <form className='project-form' >
            <h3><label>Update Project</label></h3>
            <select
              value={selectedProject}
              onChange={setSelectedProject}
              id='project_id'
            >
              {projects ? (
                <>
                  <option disabled value="">
                    Select a Project for the Event
                  </option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.title}
                    </option>
                  ))}
                </>
              ) : (
                <option disabled>Loading projects...</option>
              )}
            </select>
            <div className='admin-input-container'>

            </div>
            <button onClick={onClose} className='modal-button'> Cancel</button>
            <button type='submit' className='modal-button'> Submit</button>
          </form>

        </div>

      </div>

    </div>
  )
}

export default EditProjectModal
