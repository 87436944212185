import React, {useState} from 'react';
import '../styles/DonateModalComponent.css';
import { useAuth } from '../auth/authProvider';

const ModalComponent = ({ projectData, onClose, currentUser }) => {
  const [selectedPlan, setSelectedPlan] = useState('')
  const [amount, setAmount] = useState(0)
  const [recurring, setRecurring] = useState(true)
  const auth = useAuth()

  console.log(selectedPlan)

  const subscriptionOptions = [
    { label: 'Individual Subscription', link: 'https://buy.stripe.com/fZebJL5wW4od6E84gg' } ,
    // { label: 'Individual Subscription', link: 'https://buy.stripe.com/test_8wM7v1fA3dqK0CY000' },
    { label: 'One-Time Donation', link: 'https://buy.stripe.com/6oEcNPe3sdYNd2waEH' },
  ];

  const handleSelection = (link) => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };

  //business 25: price_1OtaBJCXtI9P4SQ0ZgUZn53s
  //business 50: price_1Oed8nCXtI9P4SQ03etyKTFu
  //individual 5 actual: price_1NgG9DCXtI9P4SQ0FicveEuD
  // onetimeactual: 'price_1Oego1CXtI9P4SQ0JMZDieOo'

  const priceIds = {
    individual5: 'price_1NgG9DCXtI9P4SQ0FicveEuD',
    business20: 'price_1P2ymuCXtI9P4SQ0VxnZTbsD',
    nonprofit10: 'price_1P2ytYCXtI9P4SQ0DfRqAth1',
    oneTime: 'price_1Oego1CXtI9P4SQ0JMZDieOo'
  }

  function getKeyByValue(object, value) {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null; // Return null if value is not found
  }

  const handleClick = (planId) => {

    switch (planId) {
      case 'individualPriceId':
      setSelectedPlan(priceIds.individual5)
      setAmount(5000)
        setRecurring(true)
        break
      case 'bus20PriceId':
        setSelectedPlan(priceIds.business20)
        setAmount(20000)
        setRecurring(true)
        break
      case 'non10PriceId':
        setSelectedPlan(priceIds.nonprofit10)
        setAmount(10000)
        setRecurring(true)
        break
      case 'oneTimePriceId':
        // setAmount(25000)
        setSelectedPlan(priceIds.oneTime)
        setRecurring(false)
        break
      default:
        console.error('Unexpected planId:', planId);
        break;
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      userId : currentUser ? currentUser.id: auth.user.id,
      email: currentUser ? currentUser.email : auth.user.email,
      amount: amount,
      recurring: recurring,
      priceId: selectedPlan,
      projectId: projectData ? projectData.id : null,
      plan: getKeyByValue(priceIds, selectedPlan)
    }
    console.log(data)
    fetch(`/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if (!res.ok) {
        throw new Error('Network response was not okay')
      }
      return res.json()
    })
    .then((data) => {
      window.open(data.url, '_blank')
    })
    .catch(err => console.log(err))

  }

    return (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Choose your support option</h3>
            {auth.user ? (
              <div className="donation-options">
                <form onSubmit={handleSubmit} className='donation-options' value='individualPriceId'>
                  <button onClick={() => handleClick('individualPriceId')} className="option-button">Individual Subscription</button>
                </form>
                <form onSubmit={handleSubmit}className='donation-options' value='non10PriceId'>
                  <button onClick={() => handleClick('non10PriceId')} className="option-button">Non-Profit Subscription</button>
                </form>
                <form onSubmit={handleSubmit}className='donation-options' value='bus20PriceId'>
                  <button onClick={() => handleClick('bus20PriceId')} className="option-button">Business Subscription</button>
                </form>
                <form onSubmit={handleSubmit}className='donation-options'>
                  <button onClick={() => handleClick('oneTimePriceId')} className="option-button">One-Time Donation</button>
                </form>
              </div>
            ): (
              <div className="donation-options">
                {subscriptionOptions.map((option, index) => (
                  <button
                    key={index}
                    className="option-button"
                    onClick={() => handleSelection(option.link)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
            <button onClick={onClose} className="cancel-button">Cancel</button>
          </div>
        </div>
      );
    };

    export default ModalComponent;
