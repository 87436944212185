import React from 'react';
import '../styles/SubscribeButton.css'; // Ensure this CSS file is created for styling

// Accept onClick prop to handle button click
const SubscribeButton = ({ onClick }) => {
  // Enhanced onClick handler with console log
  const handleClick = () => {
    onClick(); // Call the onClick prop function
  };

  return (
    <button className="subscribe-button" onClick={handleClick}>
      Subscribe
    </button>
  );
};

export default SubscribeButton;
