import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SignUp.css';
import banner from '../images/ama-banner-full.png'; // Ensure this path is correct
import { useAuth } from '../auth/authProvider';

function SignUp() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = useAuth()

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setError('');

    const values = {username, email, password}
    auth.onAuthenticate(values, true)
  };

  return (
    <div className="login-page">
      <div className="banner-container">
        <img src={banner} alt="AMA Banner" className="home-banner" />
        <div className="form-container">
          <form onSubmit={handleSignUp} className="login-form">
            {error && <p className="error-message">{error}</p>}
            <div className="input-container">
              <input
                type="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                required
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                required
              />
            </div>
            <div className="button-container">
              <button type="submit" className="login-button">Sign Up</button>
            </div>
          </form>
          <button onClick={() => navigate('/login')} className="login-button">Already have an account? Log In</button>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
