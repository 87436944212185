import React, { useState } from 'react';
import '../styles/PlayerSignInModal.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUI } from '../utils/UIContext';
import { useNavigate } from "react-router-dom";
import { useGame } from '../auth/gameProvider';

const ReferralSignInModal = ({ onClose, playerId }) => {
  const [email, setEmail] = useState('');
  const [joinNewsletter, setJoinNewsletter] = useState(false)
  const { handleNewAlert, handleAlertType } = useUI();
  const [isSignUp, setIsSignUp] = useState(true)
  const navigate = useNavigate()
  const game = useGame()


  const subscribeSchema =
    yup.object().shape({
      name: yup.string().required('Please enter a valid username'),
      email: yup.string().email().required('Please enter a valid email')
    })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      referring_player_id: playerId,
    },
    validationSchema: subscribeSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isSignUp) {
          game.onPlay(values, true)
        }

        // API call to MailChimp
        if (joinNewsletter) {
          try {
            const mailChimpResponse = await fetch("/api/newsletter-signup", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify({ "email": values.email })
            });

            if (!mailChimpResponse.ok) {
              throw new Error('Failed to add subscriber to MailChimp');
            }

          } catch (error) {
            console.error('MailChimp error:', error);
            throw new Error(error.message);
          }
        }

        resetForm();
        onClose();
        if (!joinNewsletter) {
          handleNewAlert("Joined Sweepstakes! Complete more tasks to increase your chances of winning!")
          handleAlertType('success')
        }
        else {
          handleNewAlert("Thanks for joining the newsletter! You earned 1 point!")
          handleAlertType('success')
        }

      } catch (error) {
        throw new Error(error.message);
      }
    }
  });

  const handleJoinNewsletter = () => {
    setJoinNewsletter(!joinNewsletter)
  }

  return (
    <div className='news-modal-overlay'>
      <div className='news-modal-content'>
        <div className='news-modal-header'>
          <button onClick={onClose} id='modal-exit'>X</button>
          <h1>Join Our Sweepstakes</h1>
        </div>
        <div className='news-modal-body'>
            <p>Enter our weekly and monthly sweepstakes for a chance to win exciting prizes! Boost your odds by completing tasks such as signing up for our newsletter, creating a profile, and more.</p>
          <form onSubmit={formik.handleSubmit}>

              <input
                type='name'
                placeholder='Enter your username'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='name'
                required
              />
            {formik.errors.name && formik.touched.name ? <div>{formik.errors.name}</div> : null}
            <input
              type="email"
              placeholder="Enter your email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='email'
              required
            />
            {formik.errors.email && formik.touched.email ? <div>{formik.errors.email}</div> : null}
            {isSignUp && (
              <div className='newsletter-checkbox'>
                <input
                  type='checkbox'
                  checked={joinNewsletter}
                  onChange={handleJoinNewsletter}
                  id='joinNewsletter'
                />
                <label htmlFor="joinNewsletter">Join Monthly Newsletter</label>
              </div>
            )}
            <button type="submit">Join</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReferralSignInModal
