import React, { useCallback, useMemo, useState, useEffect } from 'react';
import '../styles/Leaderboard.css';

function Leaderboard() {
  const [choiceIndex, setChoiceIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  
  // console.log(users);

  const choices = useMemo(() => [
    {
      name: "Donations",
      attr: "total_donations"
    },
    {
      name: "Events Attended",
      attr: "events_attended"
    }
  ], []);

  const compare = useCallback((a, b) => {
    const k = choices[choiceIndex].attr;
    const x = Number(a[k]);
    const y = Number(b[k]);
    return y - x;
  }, [choices, choiceIndex]);

  const changeChoice = (event) => {
    setChoiceIndex(event.target.value);
    setUsers(users.toSorted(compare));
  }

  useEffect(() => {
    // Function to fetch leaderboard data
    const fetchLeaderboardData = async () => {
      setLoading(true);
      try {
        const response = await fetch('/users');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        //sort users by number of events attended
        //!UI -- Eventually two separate columns, sort by events attended and sort by donations
        setUsers(data.toSorted(compare));
      } catch (err) {
        console.error('Failed to fetch leaderboard data:', err);
        setError('Failed to load leaderboard. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardData();

    // Set up polling to refresh data every X milliseconds
    const interval = setInterval(fetchLeaderboardData, 30000); // Adjust the 30000ms (30 seconds) interval as needed

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [compare]);

  if (loading) return <div className="loading-message">Loading leaderboard...</div>;
  if (error) return <div className="error-message">{error}</div>;


  return (
    <div className="leaderboard-container">
      <h2 className="leaderboard-title">Leaderboard</h2>
      <select
        id="sort-dropdown"
        onChange={changeChoice}
        defaultValue={choiceIndex}
        style={{ fontFamily: "Raleway" }}
      >
        <option value="" disabled>
          Sort By
        </option>
        {choices.map((choice, index) => (
          <option key={index} value={index}>
            <span style={{ fontFamily: "Raleway" }}>{choice.name}</span>
          </option>
        ))}
      </select>
      <ol className="leaderboard-list">
        {users.map((user, index) => (
          <li key={index}>
            <span className="leaderboard-user">
              {user.username ? user.username : 'Anonymous Activist'}
            </span>
            <span className="leaderboard-value">
              {user[choices[choiceIndex].attr]}
            </span>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default Leaderboard;
