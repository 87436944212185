import React, { useEffect, useState } from "react";
import "../styles/ReferralModal.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGame } from '../auth/gameProvider';
import { useUI } from "../utils/UIContext";

const RestoraionModal = ({ onClose }) => {
  const { handleNewAlert, handleAlertType } = useUI();
  const {player} = useGame();

  const formik = useFormik({
    initialValues: {
      location: "",
      player_id: player.id,
      email: player.email,
    },

    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch("/api/restoration", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          body: JSON.stringify(values),
        });
        if (!response.ok) {
          handleNewAlert("Failed to submit location, please try again.");
          handleAlertType("error");
          throw new Error("Failed to submit location");
        }
        handleNewAlert("Location submitted successfully! You earned 1 point.");
        handleAlertType("success");
        resetForm();
      } catch (error) {
        handleNewAlert("An error occurred. Please try again.");
        handleAlertType("error");
      }
      onClose();
    },
  });

  return (
    <div className="news-modal-overlay">
      <div className="news-modal-content">
        <div className="news-modal-header">
          <button onClick={onClose} id="modal-exit">
            X
          </button>
          <h1>Restoring Nature's Beauty</h1>
        </div>
        <div className="news-modal-body">
          <p>
            Recommend a natural site that could use our help in its restoration.
            Your suggestions will guide our efforts to rejuvenate and preserve
            these important spaces.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              placeholder="Suggest"
              value={formik.values.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="location"
              required
            />
            {formik.errors.location && formik.touched.location ? (
              <div>{formik.errors.location}</div>
            ) : null}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RestoraionModal;
