import React from 'react';
import '../styles/Memberships.css';

function Memberships() {

  const handleSubscribe = (url) => {
    window.open(url, "_blank");
  };

  const stripeUrls = {
    individual: "https://buy.stripe.com/fZebJL5wW4od6E84gg",
    oneTimeDonation: "https://buy.stripe.com/6oEcNPe3sdYNd2waEH"
  };

  return (
    <div className="memberships-container">
      {/* Hero Section */}
      <div className="hero">
        <h1 className="hero-title">Join the Movement to Heal Our Planet</h1>
        <p className="hero-subtitle">Make a tangible impact by supporting our nature conservation projects</p>
      </div>

      <div className="membership-tiers">
        {/* Mission Info Box */}
        <div className="mission-box">
          <h2>Why Subscribe to Ama Earth Group?</h2>
          <p>
            By subscribing, you're joining a community-led effort to restore the planet.
            
            Your contribution helps fund vital climate action projects, including reforestation, wildlife protection, and ocean cleanups.
          </p>
          <button className="cta-button" onClick={() => handleSubscribe(stripeUrls.individual)}>Subscribe Now</button>
        </div>

        {/* Individual Plan */}
        <div className="tier premium-card">
          <div className="deal-banner">Free for 1 month</div>
          <h2 className="plan-title">Individual</h2>
          <p className="price">$5.00 <span>/ month</span></p>
          <div className="price-divider"></div>  {/* Added white line divider */}
          <ul className="features">
            <li>1 Community Entry Pass</li>
            <li>Access to our climate products*</li>
            <li>Support specific projects</li>
            <li>Exclusive giveaways</li>
            <li>Cancel anytime</li>
          </ul>
          <button className="cta-button" onClick={() => handleSubscribe(stripeUrls.individual)}>Accept 1 Month Free</button>
          {/* Footnote Section */}
          <p className="footnote">
            *Includes our Mangrove Mapping Application (MMAPP) & Eco-Travel Application
          </p>
        </div>

        {/* One-Time Donation Plan */}
        <div className="tier premium-card">
        <div className="deal-banner">Make a Difference</div>
          <h2 className="plan-title">One-Time Donation</h2>
          <p className="price">Any Amount</p>
          <div className="price-divider"></div>  {/* Added white line divider */}
          <ul className="features">
            <li>Contribute to climate action</li>
            <li>100% goes to projects</li>
          </ul>
          <button className="cta-button" onClick={() => handleSubscribe(stripeUrls.oneTimeDonation)}>Donate Now</button>
        </div>
      </div>
    </div>
  );
}

export default Memberships;
