import React, {useState, useEffect} from 'react';
import '../styles/AdminModals.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUI } from '../utils/UIContext';
import { useAuth } from '../auth/authProvider';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const AddEventModal = ({onClose}) => {
  const [date, setDate] = useState(null)
  const [projects, setProjects] = useState([])
  const { handleNewAlert, handleAlertType } = useUI();

  //initial fetch of projects
  useEffect(() => {
    fetch('/projects')
      .then(r => r.json())
      .then(setProjects)
      .catch(err => console.log(err));
  }, []);


  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setDate(formattedDate); // Update testValue with formatted date
    formik.setFieldValue('date', formattedDate); // Update formik values with formatted date
  }

  const eventSchema = yup.object().shape({
    title: yup.string().required('Please enter a date'),
    date: yup.string().required('Please enter valid date'),
    project_id: yup.number().required('Please select a project'),
    location: yup.string().required('Please enter a location or TBD'),
    info: yup.string().required('Please enter info about the event')
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      date: '',
      project_id: '',
      location: '',
      info: ''
    },
    validationSchema: eventSchema,
    onSubmit: async (values, {resetForm}) => {
      const response = await fetch('/events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(values)
      })

      if (!response.ok) {
        handleNewAlert('Failed to create new event');
        handleAlertType('error');
        throw new Error('Failed to create new event');
      }
      resetForm()
      setDate(null)
      handleNewAlert('Event Added!');
      handleAlertType('success');
      onClose()
    }
  })


  return (
    <div className='admin-modal-overlay'>
      <div className='admin-modal-content'>
        <div className='admin-form-container'>
          <form className='project-form' onSubmit={formik.handleSubmit}>
            <h3><label>Add New Event</label></h3>
            <div className='admin-input-container'>
              <input
                value={formik.values.title}
                placeholder='Event Title'
                required
                name='title'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.title && formik.touched.title ? < >{formik.errors.title}</> : null}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={{paddingTop: '0',alignItems: 'center', justifyContent:'center', border: 'none'} }
                  components={['DatePicker']}>
                  <DatePicker
                    id='date-picker'
                    value={date ? dayjs(date) : null}
                    onChange={handleDateChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {/* {formik.errors.date && formik.touched.date ? < >{formik.errors.date}</> : null} */}
              <input
                value={formik.values.location}
                placeholder='Event Location'
                required
                name='location'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.location && formik.touched.location ? < >{formik.errors.location}</> : null}
              <textarea
                value={formik.values.info}
                placeholder='Event Information'
                required
                name='info'
                rows='5'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.info && formik.touched.info ? < >{formik.errors.info}</> : null}
              <select
                value={formik.values.project_id}
                onChange={formik.handleChange}
                id='project_id'
              >
                {projects ? (
                  <>
                    <option disabled value="">
                      Select a Project for the Event
                    </option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.title}
                      </option>
                    ))}
                  </>
                ) : (
                  <option disabled>Loading projects...</option>
                )}
              </select>
            </div>
            <button onClick={onClose} className='modal-button'> Cancel</button>
            <button type='submit' className='modal-button'> Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddEventModal
