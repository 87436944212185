import React from 'react';
import { useAuth } from './authProvider';
import { Navigate } from 'react-router-dom';

const RequireAdmin = ({ children }) => {
  const auth = useAuth()

  if (!auth.user || !auth.user.is_admin) {
    return <Navigate to='/profile' />
  }

  return children
}

export default RequireAdmin
