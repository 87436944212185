// LoginButton.js

import React from "react";
import { useNavigate } from "react-router-dom";

function LoginButton() {
  const navigate = useNavigate(); // Use navigate inside the component

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to login page when button is clicked
  };

  return (
    <div className="fixed-login-button">
      <button className="login-button" onClick={handleLoginClick}>Login</button>
    </div>
  );
}

export default LoginButton;
