
import React from 'react';

function Cancel() {
  return (
    <div>
      <h1>Payment Cancelled</h1>
      <p>You have not been charged. Please try again or contact support if you continue to have issues.</p>
    </div>
  );
}

export default Cancel;