import React, {useState} from 'react';
import { Box, Typography, List, ListItemText, Avatar, Button, ListItem, } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Unstable_Grid2';
import '../styles/UploadPhoto.css';
import defaultPhoto from '../images/photo_default.jpg';
import { useGame } from '../auth/gameProvider';
import { useUI } from '../utils/UIContext';
import Confetti  from 'react-confetti';
import { useNavigate, Link } from "react-router-dom";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

//!YOU MUST CHECK AGAIN ONCE LOGIN IS FUNCTIONAL

const UploadPhoto = () => {
  const game = useGame();
  const { handleNewAlert, handleAlertType } = useUI();
  const [photo, setPhoto] = useState();
  const [preview, setPreview] = useState();
  const [showConfetti, setShowConfetti] = useState(false)
  const {player} = useGame()
  const navigate = useNavigate()


  const handleChange = (e) => {
    const file = e.target.files[0]

    setPhoto(file)

    const url = URL.createObjectURL(file)
    setPreview(url)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!photo || !player) return;

    const submitPhoto = async () => {
      const formData = new FormData()
      formData.append('file', photo)
      formData.append('player_id', player.id)
      formData.append('email', player.email)
      try {
        const response = await fetch('/api/photo-challenge-upload', {
          method: 'POST',
          body: formData,
        })

        if (!response.ok) {
          throw new Error('Failed to upload challenge photo')
        }
        const data = await response.json() //potential to use this image?
        handleNewAlert("Uploaded Photo!")
        handleAlertType('success')

        // setShowConfetti(true)
        // setTimeout(() => {
        //   setShowConfetti(false)
        // }, 6000);

      } catch (error) {
        handleNewAlert("Failed to upload photo")
        handleAlertType('error')
    }}

    submitPhoto()
    setShowConfetti(true)
    setTimeout(() => {
      setShowConfetti(false)
      navigate("/lobby", { replace: true })
    }, 3000);
    setPhoto(null)
      }



  return (
    <Box sx={{ width: '100%', flexGrow: 1, ml: 0, mr: 0, mt: 4, mb: 8 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography sx={{mt: '1rem', mb:'1rem'}} fontFamily='inherit' variant='h4'>Photo Challenge</Typography>
          <Typography sx={{ mt: '1rem', mb: '1rem' }} fontFamily='inherit' variant='h5'>Upload a photo of a tree to increase your odds in the sweepstakes.</Typography>
        </Grid>
        <Grid xs={12}>
          <img className='challenge-image' src={photo ? preview : defaultPhoto} alt='tree'/>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <form className='photo-form' onSubmit={handleSubmit}>
            <Grid xs={12}>
              <Button
                sx={{
                  backgroundColor: '#3CB371',
                  '&:hover': {
                    backgroundColor: '#2E8B57',
                  },
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                {/* <VisuallyHiddenInput onChange={(e) => setPhoto((e.target.files[0]))} type="file" /> */}
                <VisuallyHiddenInput onChange={(e) => handleChange(e)} type="file" />
              </Button>
            </Grid>
            <Grid xs={12}>
              {photo ? (
                <Button
                  type='submit'
                  onSubmit={handleSubmit}
                  sx={{
                    color: 'black',
                    backgroundColor: '#d6d6d6',
                    '&:hover': {
                      backgroundColor: '#ffffff',
                    },
                  }}
                >Submit to earn +1 point! 🎉</Button>
              ) : (
                null
              )}
            </Grid>
          </form>
          <button onClick={() => navigate("/lobby", { replace: true })} id='toggle-signup'>Return to Lobby</button>
        </Grid>


      </Grid>
        {showConfetti &&
        <Confetti
          size={8}
          shape="circle"
        colors={['#f44336', '#9c27b0', '#3f51b5', '#2196f3', '#4caf50', '#ffeb3b', '#ff9800', '#ff5722', '#607d8b']
}
          wind={0}
          gravity={0.05}
        />}

    </Box>
  )
}

export default UploadPhoto
