// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/ama-logo.png';
import banner from '../images/ama-banner-full.png'; // Make sure the path is correct
import '../styles/Navbar.css';
import { useAuth } from '../auth/authProvider';

function Navbar() {
  const auth = useAuth();

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-brand">
        <img src={logo} alt="AMA Logo" className="navbar-logo" />
      </Link>
      <ul className="nav-links">
        <li><Link to="/about" className="nav-item">About</Link></li>
        <li><Link to="/projects" className="nav-item">Projects</Link></li>
        <li><Link to="/memberships" className="nav-item">Memberships</Link></li>
{/* <li><Link to="/leaderboard" className="nav-item">Leaderboard</Link></li> */}
        {auth.user && auth.user.is_admin ? (
          <li><Link to="/admin" className="nav-item">Admin Dashboard</Link></li>
        ): (
          <li><Link to="/dashboard" className="nav-item">Dashboard</Link></li>
        )}

        {/* <li className="game-tab"><Link to="/" className="nav-item">Giveaway</Link><p>New!</p></li> */}
        <li className="game-tab"><Link to="/lobby" className="game-tab-text">Game</Link></li>
        <li><Link to="/profile" className="nav-item">Profile</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
