import React, { useEffect, useState } from "react";
import "../styles/ReferralModal.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUI } from "../utils/UIContext";
import { useGame } from '../auth/gameProvider';

const FeedbackModal = ({ onClose, playerId }) => {
  const { handleNewAlert, handleAlertType } = useUI();
  const {player} = useGame();

  const formik = useFormik({
    initialValues: {
      feedback: "",
      player_id: player.id,
      email: player.email,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch("/api/feedbacktask", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          body: JSON.stringify(values),
        });
        if (!response.ok) {
          handleNewAlert("Something went wrong, please try again.");
          handleAlertType("error");
          throw new Error("Failed to submit feedback");
        }
        handleNewAlert("Thank you for your feedback!");
        handleAlertType("success");
        resetForm();
      } catch (error) {
        handleNewAlert("An error occurred. Please try again.");
        handleAlertType("error");
      }
      onClose();
    },
  });

  return (
    <div className="news-modal-overlay">
      <div className="news-modal-content">
        <div className="news-modal-header">
          <button onClick={onClose} id="modal-exit">
            X
          </button>
          <h1>Share Your Thoughts! 🎡</h1>
        </div>
        <div className="news-modal-body">
          <p>
            We'd love to hear what you think about our game! Your feedback helps
            us improve and makes the game even better. As a thank you for your
            input, you'll earn 1 point just for sharing your thoughts.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              placeholder="Enter your feedback"
              value={formik.values.feedback}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="feedback"
              required
            />

            <button
              style={{ fontWeight: "bold" }}
              type="submit"
              onSubmit={formik.handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
