
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from '../images/ama-logo.png';
import '../styles/Success.css'

const Success = () => {
  const location = useLocation()
  const [sessionData, setSessionData] = useState(null)
  const [sessionId, setSessionId] = useState(null)
  const [subscriptionPlan, setSubscriptionPlan] = useState(null)

  // console.log(sessionData.amount_total/100)


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    setSessionId(sessionId)

    const fetchSession = async () => {
      try {
        const response = await fetch(`/get-checkout-session/${sessionId}`)
        if (!response.ok) {
          throw new Error(`HTTP error! status ${response.status}`)
        }
        const data = await response.json()
        setSessionData(data.session_obj)
      }
      catch (err) {
        console.log(err)
      }
    }


    fetchSession()
  }
  ,[location.search])

  useEffect(() => {
    if (sessionData && sessionId) {
      updateDatabase();
    }
  }, [sessionData, sessionId]);

  const updateDatabase = async () => {
    let path;
    let values;
    if (sessionData.mode === 'subscription') {
      path = '/subscription-donations'
      values = {
        project_id: parseInt(sessionData.metadata.project_id),
        user_id: parseInt(sessionData.metadata.user_id),
        start_date: sessionData.metadata.start_date,
        status: sessionData.metadata.status,
        session_id: sessionId,
        subscription_plan: sessionData.metadata.subscription_plan,
        amount: sessionData.amount_total / 100
      }
    }
    else {
      path = '/one-time-donations'
      values = {
        project_id: parseInt(sessionData.metadata.project_id),
        user_id: parseInt(sessionData.metadata.user_id),
        session_id: sessionId,
        amount: sessionData.amount_total / 100
      }
    }
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(values)
      })
      if (!response.ok) {
        throw new Error('Failed to add new donation instance')
      }
      const newSubPlan = await response.json()
      setSubscriptionPlan(newSubPlan)
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <img src={logo} alt="AMA Logo" />
      <h1>Your Payment was Successful!</h1>
      <p>Thank you for your payment. An automated receipt will be sent to your registered email.</p>
      <Link className='success-link' to='/projects'>Return to Projects</Link>
    </div>
  );
};


export default Success
