
import React from 'react';

function FeedbackForm() {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <iframe
        src="https://forms.gle/czdREYFphQW7EZ1DA"
        width="100%"
        height="100%"
        style={{ border: 'none', width: '100vw', height: '100vh' }}
        title="Feedback Form"
      ></iframe>
    </div>
  );
}

export default FeedbackForm;
