import React, { useEffect, useState } from "react";
import "../styles/ReferralModal.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGame } from '../auth/gameProvider';
import { useUI } from "../utils/UIContext";

const InstaModal = ({ onClose, playerId }) => {
  const { handleNewAlert, handleAlertType } = useUI();
  const {player} = useGame();

  const formik = useFormik({
    initialValues: {
      username: "",
      player_id: player.id,
      email: player.email,
    },

    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch("/api/submit_username", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          body: JSON.stringify(values),
        });
        if (!response.ok) {
          handleNewAlert("Failed to submit username, please try again.");
          handleAlertType("error");
          throw new Error("Failed to submit username");
        }
        handleNewAlert("Username submitted successfully! You earned 1 point.");
        handleAlertType("success");
        resetForm();
      } catch (error) {
        handleNewAlert("An error occurred. Please try again.");
        handleAlertType("error");
      }
      onClose();
    },
  });

  return (
    <div className="news-modal-overlay">
      <div className="news-modal-content">
        <div className="news-modal-header">
          <button onClick={onClose} id="modal-exit">
            X
          </button>
          <h1>Insta Activity! 📷</h1>
        </div>
        <div className="news-modal-body">
          <p>
            Follow us on instagram{" "}
            <span style={{ color: "#32CD32", fontWeight: "bold" }}>
              {" "}
              @amaearthgroup{" "}
            </span>
            <br /> Comment on any post you like & submit your username to earn 1
            point.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              placeholder="Enter your Instagram username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="username"
              required
            />
            {formik.errors.username && formik.touched.username ? (
              <div>{formik.errors.username}</div>
            ) : null}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InstaModal;
