import React, {useState} from 'react'
import '../styles/AdminDashboard.css';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import EventIcon from '@mui/icons-material/Event';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import AddProjectModal from '../components/AddProjectModal';
import AddEventModal from '../components/AddEventModal';
import EditEventModal from '../components/EditEventModal';
import EditProjectModal from '../components/EditProjectModal';

const AdminDashboard = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedModal, setSelectedModal] = useState('')


  const handleClick = (name) => {
    setSelectedModal(name)
    switch (name) {
      case 'add-project':
      setModalOpen(true)
        break
      case 'add-event':
        setModalOpen(true)
        break
      case 'update-users':
        setModalOpen(true)
        break
      case 'update-project':
        setModalOpen(true)
        break
      case 'update-event':
        setModalOpen(true)
        break
      default:
        console.log('Available button not selected')
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <div className='admin-container'>
    <h1 className='admin-header'>Admin Dashboard</h1>
      <div className="admin-button-container">
          <button onClick={() => handleClick('add-project')} name='add-project' className="admin-button">
            <TextSnippetRoundedIcon name='add-project' className='admin-button-icon'/>
            <div className="admin-button-text">Add Project</div>
          </button>
          <button onClick={() => handleClick('add-event')} name='add-event' className="admin-button">
            <EventIcon className='admin-button-icon'/>
            <div className="admin-button-text">Add Event</div>
          </button>
          <button onClick={() => handleClick('update-users')} name='update-users' className="admin-button">
            <PeopleAltRoundedIcon className='admin-button-icon'/>
            <div className="admin-button-text">Update Users</div>
          </button>
          <button onClick={() => handleClick('update-project')} name='update-project' className="admin-button">
            <EditNoteRoundedIcon className='admin-button-icon'/>
            <div className="admin-button-text">Update Project</div>
          </button>
          <button onClick={() => handleClick('update-event')} name='update-event'className="admin-button">
            <EditCalendarRoundedIcon className='admin-button-icon'/>
            <div className="admin-button-text">Update Event</div>
          </button>
      </div>
      {selectedModal === 'add-project' && modalOpen ? <AddProjectModal onClose={handleCloseModal}/>: null}
      {selectedModal === 'add-event' && modalOpen ? <AddEventModal onClose={handleCloseModal}/>: null}
      {selectedModal === 'update-event' && modalOpen ? <EditEventModal onClose={handleCloseModal}/>: null}
      {selectedModal === 'update-project' && modalOpen ? <EditProjectModal onClose={handleCloseModal}/>: null}

    </div>
  )
}

export default AdminDashboard
