// VolunteerModalComponent.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import '../styles/VolunteerModalComponent.css'
import { useAuth } from "../auth/authProvider";
import { useUI } from '../utils/UIContext';

function VolunteerModalComponent({ onClose, selectedVolunteerEvents, onVolunteer }) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsAttending, setEventsAttending] = useState(null)
  const auth = useAuth()
  const { handleNewAlert, handleAlertType } = useUI();

  console.log(selectedVolunteerEvents)

  // Temporarily storing registration links for events
  const registrationLinks = {
    1: "https://www.eventbrite.com/e/1st-annual-passaic-river-day-tickets-907062387017?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl",
  };


  //Fetch event attendees when the modal is opened
  useEffect(() => {
    const fetchAttendees = async () => {
      if (!auth.user) return
      try {
        const response = await fetch(`/eventattendees`)
        if (!response.ok) {
          throw new Error('Failed to fetch event attendees')
        }
        const data = await response.json()
        const filteredData = data.filter((attendee) => attendee.user_id === auth.user.id)
        setEventsAttending(filteredData)
      }
      catch (error) {
        console.log(error)
      }
    }
    fetchAttendees()
  },[])

  // Function to handle selecting an event to volunteer for
  const handleEventSelection = (event) => {
    setSelectedEvent(event);
  };

  // Function to handle the volunteer action for the selected event
  const handleVolunteerForEvent = () => {
    if (selectedEvent) {
      if (auth.user) {
        onVolunteer(selectedEvent); // Call the passed in onVolunteer function with the selected event
      }
      const registrationLink = registrationLinks[selectedEvent.id];
      if (registrationLink) {
        window.open(registrationLink, "_blank");
        onClose()
      } else {
        handleNewAlert('No Registration Link at this time')
        handleAlertType('info')
      }
      handleNewAlert("Successfully joined!")
      handleAlertType('success')
    }
  };

  // Format the date without time
  const formatDate = (dateString) => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  // Format the time in local time zone
  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(timeString).toLocaleTimeString('en-US', options);
  }

  // Get the timezone abbreviation
  const getTimezoneAbbreviation = () => {
    return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  }

  //Check if someone has already volunteer for an event
  const hasVolunteered = (eventId) => {
    if (!eventsAttending) {
      return false
    }
    return eventsAttending.some((instance) => instance.event_id === eventId)
  }


  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Volunteer Events Modal"
      className="volunteer-modal"
      overlayClassName="volunteer-modal-overlay"
    >
      <div className="volunteer-modal-content">
        <div className="volunteer-modal-header">
          <h2 className="volunteer-modal-title">Upcoming Volunteer Events</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="volunteer-modal-body">
          {Array.isArray(selectedVolunteerEvents) ? (
            selectedVolunteerEvents.length > 0 ? (
              <>
                {console.log('I am in the first no length 0')}
                {selectedVolunteerEvents.map((event, index) => (
                  <div key={index} className="event-item">
                    <div className="event-info">
                      <h3>{event.title}</h3>
                      <h5>🗓️ {formatDate(event.date_starttime)} · {formatTime(event.date_starttime)} - {formatTime(event.date_endtime)} {getTimezoneAbbreviation()}</h5>
                      <h5>📍 {event.location}</h5>
                      <p>{event.info}</p>
                    </div>
                    <button
                      className={`event-button ${selectedEvent === event ? "selected" : ""}`}
                      onClick={() => handleEventSelection(event)}
                      disabled={hasVolunteered(event.id)}
                    >
                      {!hasVolunteered(event.id)? 'Select' : 'Already Attending'}
                    </button>
                  </div>
                ))}
                <button
                  className={`volunteer-confirm-button ${!selectedEvent ? 'disabled' : ''}`}
                  onClick={handleVolunteerForEvent}
                  disabled={!selectedEvent}
                >
                  Register for Event
                </button>
              </>
            ) : (
              <p className='no-events'>No volunteer events available at this time.</p>
            )
          ) : (
              <>
                {console.log('I am here')}
                <div className="event-item">
                    <div className="event-info">
                      <h3>{selectedVolunteerEvents.title}</h3>
                      <h5>🗓️ {formatDate(selectedVolunteerEvents.date_starttime)} · {formatTime(selectedVolunteerEvents.date_starttime)} - {formatTime(selectedVolunteerEvents.date_endtime)} {getTimezoneAbbreviation()}</h5>
                      <h5>📍 {selectedVolunteerEvents.location}</h5>
                      <p>{selectedVolunteerEvents.info}</p>
                    </div>
                    <button
                      className={`event-button ${selectedEvent === selectedVolunteerEvents ? "selected" : ""}`}
                      onClick={() => handleEventSelection(selectedVolunteerEvents)}
                      disabled={hasVolunteered(selectedVolunteerEvents.id)}
                    >
                      {!hasVolunteered(selectedVolunteerEvents.id) ? 'Select' : 'Already Attending'}
                    </button>

                </div>
                <button
                  className={`volunteer-confirm-button ${!selectedEvent ? 'disabled' : ''}`}
                  onClick={handleVolunteerForEvent}
                  disabled={!selectedEvent}
                >
                  Register for Event
                </button>
              </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default VolunteerModalComponent;
